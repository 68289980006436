import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import AboutUsContainer from '../containers/AboutUsContainer'

const AboutUsPage = (props) => (
  <Layout 
  pageInfo={{ pageName: "About Us" }} 
  styleClass="home-page" 
  mainClass="main-about"
  location={props.location.pathname}>
    <SEO title="About Us" />
    <AboutUsContainer />
  </Layout>
)

export default AboutUsPage
